<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="900px">
        <v-card>
          <v-card-title>
            <span class="headline">Nuevo Registro</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p v-if="responseError && !valid">
                Los datos enviados no son correctos, haga click sobre la X de
                los campos inválidos para intentar de nuevo.
              </p>
              <v-form ref="myform" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Fecha de recibido"
                      type="date"
                      v-model="body.received_date"
                      :rules="onlyRequired"
                      :error-messages="receivedDateError"
                      @click:clear="receivedDateError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Descripción"
                      v-model="body.description"
                      :error-messages="descriptionError"
                      @click:clear="descriptionError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="body.quantity"
                      label="Cantidad"
                      :rules="valueRules"
                      :error-messages="quantityError"
                      @click:clear="quantityError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="body.counter"
                      label="Contador"
                      :rules="onlyRequired"
                      :error-messages="counterError"
                      @click:clear="counterError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Precio unitario"
                      v-model="body.unit_price"
                      :error-messages="unitPriceError"
                      @click:clear="unitPriceError = []"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Total"
                      v-model="body.total"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="create()"
              :disabled="!valid"
              :loading="loading"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  props: {
    dialog: { type: Boolean, required: true },
  },
  data: () => {
    return {
      loading: false,
      responseError: false,
      valid: false,
      quantityError: [],
      unitPriceError: [],
      receivedDateError: [],
      descriptionError: [],
      counterError: [],
      companies: [],
      body: {
        vehicle_id: undefined,
        horometer: undefined,
        driver: undefined,
        quantity: undefined,
        price: undefined,
        total: 0.0,
        counter: undefined,
      },
      //rules
      onlyRequired: [rules.required],
      valueRules: [rules.required, rules.minValue(0.1)],
    };
  },
  methods: {
    close() {
      this.$refs.myform.reset();
      this.$emit("close");
    },
    create() {
      this.valid = false;
      this.loading = true;
      if (this.$refs.myform.validate()) {
        requests.post("/api/tank/", this.body).then((res) => {
          if (res.status == 201) {
            this.$toasted.global.info({
              message: "Operación realizada con éxito.",
            });
            this.close();
            this.$emit("update");
          } else if (res.status == 400) {
            this.responseError = true;
            if (res.data.quantity != undefined) {
              this.quantityError = res.data.quantity;
            }
            if (res.data.description != undefined) {
              this.descriptionError = res.data.description;
            }
            if (res.data.received_date != undefined) {
              this.receivedDateError = res.data.received_date;
            }
            if (res.data.unit_price != undefined) {
              this.unitPriceError = res.data.unit_price;
            }
            if (res.data.counter != undefined) {
              this.counterError = res.data.counter;
            }
            if (res.data.detail != undefined) {
              this.$toasted.global.info({
                message: res.data.detail,
              });
            }
          } else {
            console.log(res);
          }
        });
        this.loading = false;
      }
    },
  },
  watch: {
    body: {
      handler: function () {
        var total = 0;
        if (
          Number(this.body.unit_price).toFixed(2) > 0.01 &&
          Number(this.body.quantity).toFixed(2) >= 1
        ) {
          total =
            Number(this.body.unit_price).toFixed(2) *
            Number(this.body.quantity).toFixed(2);
        }
        this.body.total = Number(total).toFixed(2);
      },
      deep: true,
    },
  },
};
</script>